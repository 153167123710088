


    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 0 auto;
        text-align: center;

    }
   
    .paper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 40%;
        /* height: 100%; */
        text-align: center;
        box-shadow: 0 0 .3rem .2rem #185694;
        border-radius: 2rem;
        padding: 1.5rem;
        margin: 5rem 0;
        border: 2px solid black;
      
    }
   
    .group {
        width: 100% ;
        display: flex;
        padding-bottom: 2rem;
        justify-content: center;
        align-items: center;
       
    }
    .label {
        /* width: 20% ; */
        padding-right: 1rem !important;
        font-size: 1.4rem !important;
        text-align: left;
     

        
    }
    .text {
        margin: 3rem 0 !important;
        text-align: center;
        font-size: 2rem !important;
       
    }
    .submit {
        width: 40% !important;
        /* margin: 2rem !important; */
        padding: .8rem !important;
        /* border-radius: 1rem !important; */
        background-color: #185694 !important;
        font-size: 1rem !important
    }
    .box {
        /* width: 60%; */
        border-radius: 1rem ;
        outline: none !important;
        padding: 1rem ;
        border: .1rem solid black;
        font-size: 1.5rem
    }
  
  
