.container2{
    margin: 0rem 0rem 0rem 20rem;
    padding:0rem 0rem 0rem 0rem;
    width:30rem;
   
}
.form-floating{
    padding:0rem 0rem 0rem 0rem;
}
.thumbnail{
    margin: 0rem 0rem 0rem 0rem;
}
.course-add{
    display:grid;
    grid-template-columns: auto auto ;
    height:100%;
    margin: -28rem 0rem 0rem 20rem;
    padding:0rem 0rem 0rem 0rem;
}
.add-chapter-item{
    width:20rem;
    height:3rem;
    border:2px solid black;
    padding:0.4rem 0rem 0rem 2rem;

}


