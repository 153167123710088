.chapter-info{
    margin:-25rem 0rem 0rem 20rem;
    width:40rem;
}


  
  .registartion-form {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 600px;
    color: rgb(23, 18, 18);
    font-size: 18px;
    font-family: sans-serif;
    background-color: #cbe4f3;
    padding: 20px;
  }
  
  .registartion-form input,
  .registartion-form textarea {
    border: none;
    padding: 5px;
    margin: 1rem 0rem 0rem -14rem;
    font-family: sans-serif;
  }
  
  .registartion-form input:focus,
  .registartion-form textarea:focus {
    box-shadow: 3px 3px 10px rgb(228, 228, 228), -3px -3px 10px rgb(224, 224, 224);
  }
  
  .registartion-form .submit {
    width: 100%;
    padding: 8px 0;
    font-size: 20px;
    color: rgb(44, 44, 44);
    background-color: #ffffff;
    border-radius: 5px;
    margin:0rem 0rem 0rem 0rem;
  }
  
  .registartion-form .submit:hover {
    box-shadow: 3px 3px 6px rgb(255, 214, 176);
  }