.container1{
   margin: 0rem 0rem 0rem 17rem;
    padding: 0rem 0rem 0rem 0rem;
    width: 77rem;
}
.add-course{
   margin:-28rem 0rem 1rem 85rem;
   padding: 0rem 0rem 0rem 0rem;
}

.form-arrange{
   display: grid;
   grid-template-columns: auto auto;
   width: 100%;
   height: 91%;
}
.swal2-container {
   z-index: 100000000000000 !important;
 }